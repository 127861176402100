.lag h1 {
  display: flex;
  align-items: center;
}

.lag {
  background: rgb(38, 80, 9);
  background: -moz-linear-gradient(135deg, #021503 5%, #213e05 81%);
  background: -webkit-linear-gradient(135deg, #021503 5%, #213e05 81%);
  background: linear-gradient(135deg, #021503 5%, #213e05 81%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265009", endColorstr="#f4f1c9", GradientType=1);
  font-family: 'Arima Madurai', cursive;
  letter-spacing: .7px;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.lag a {
  color: #ffee00;
}

.lag .content {
  background: rgb(38, 80, 9);
  background: -moz-linear-gradient(135deg, #043207 5%, #3b6f0a 81%);
  background: -webkit-linear-gradient(135deg, #043207 5%, #3b6f0a 81%);
  background: linear-gradient(135deg, #043207 5%, #3b6f0a 81%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265009", endColorstr="#f4f1c9", GradientType=1);
  max-width: 800px;
  margin: auto;
  overflow: auto;
  height: 100vh;
  padding: 0 3em;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .lag .content {
    padding: 0 1em;
  }
}

.lag .store-badges {
  display: flex;
  align-items: center;
}
.lag .store-badges .badge-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000b3;
  border-radius: 9px;
  text-shadow: 0 0 2px black;
  color: white;
  font-size: 1.1em;
}

.lag .store-badges a {
  position: relative;
  padding-right: 1em;
}
.lag .store-badges img {
  display: flex;
  width: 200px;
}
