.home {
  width: 100vw;
  height: 100vh;
  animation: bg 20s linear 0s infinite normal forwards;
}

.home #site-logo {
  width: 90%;
  max-width: 500px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home .app-container {
  width: 100%;
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home .app-container a {
  border: 1px solid black;
  margin: 1em;
  border-radius: 6px;
  padding: 1em;
  cursor: pointer;
  width: 250px;
}

.home .title {
  display: flex;
  align-items: center;
}
.home .title img {
  width: 50px;
  margin-right: 0.5em;
}

.lag-link {
  text-decoration: none;
  background-color: #262c35c9;
  font-family: 'Arima Madurai', cursive;
  color: white;
}

.lag-link .title {
  font-size: 1.5em;
}

.lag-link .short-description {
  font-size: 1em;
}

.identity-link {
  background-color: #4d4d4d9e;
  color: white;
  text-decoration: none;
  font-family: 'gill', cursive;
}

.identity-link img {
  width: 200px;
}

@keyframes bg {
  0% {
    background-color: #f44336;
  }
  7.142857142857143% {
    background-color: #e91e63;
  }
  14.285714285714286% {
    background-color: #9c27b0;
  }
  21.42857142857143% {
    background-color: #673ab7;
  }
  28.571428571428573% {
    background-color: #3f51b5;
  }
  35.714285714285715% {
    background-color: #2196f3;
  }
  42.85714285714286% {
    background-color: #03a9f4;
  }
  50.00000000000001% {
    background-color: #00bcd4;
  }
  57.14285714285715% {
    background-color: #009688;
  }
  64.28571428571429% {
    background-color: #4caf50;
  }
  71.42857142857143% {
    background-color: #8bc34a;
  }
  78.57142857142857% {
    background-color: #cddc39;
  }
  85.71428571428571% {
    background-color: #ffeb3b;
  }
  92.85714285714285% {
    background-color: #ffc107;
  }
  99.99999999999999% {
    background-color: #ff9800;
  }
}
